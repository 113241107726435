import React, { Component } from 'react';
import logo from './logo.jpg';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="Screaming Horse Entertainment" />
          <h2>Screaming Horse Entertainment</h2>
          <p>We are not horsin' around!</p>
        </header>
        <footer className="App-footer">
          All right reserved, Screaming Horse Entertainment Ltd 2018
        </footer>
      </div>
    );
  }
}

export default App;
